.TechnologyPage {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  
    .navbar {
      width: 100%;
      height: 4rem;
      padding: 0 8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
  
      .hamMenu-home {
        display: none;
        position: absolute;
        left: 1rem;
        height: 100%;
  
        img {
          width: 20px;
        }
      }
  
      .logo {
        display: flex;
        cursor: pointer;
        transition: all ease 0.2s;
  
        &:hover {
          transform: scale(1.05);
        }
      }
  
      .menu-items {
        display: flex;
        gap: 2rem;
        align-items: center;
        font-size: 0.9rem;
  
        p {
          margin: 0;
          cursor: pointer;
          transition: all ease 0.2s;
  
          &:hover {
            transform: scale(1.05);
          }
        }
      }
  
      .btns-div {
        display: flex;
        gap: 1rem;
  
        .gs-Btn {
          color: white;
          font-weight: 500;
          border-radius: 35px;
          background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
          padding: 0.7rem 1.5rem;
          font-size: 0.8rem;
        }
  
        .business-btn {
          color: white;
          font-weight: 500;
          border-radius: 35px;
          background: #100f0d;
          padding: 0.5rem 1.5rem;
        }
  
        div {
          cursor: pointer;
          transition: all ease 0.2s;
  
          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  
    .color-box {
      width: 100%;
      height: 1rem;
      background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
    }
  
    .parent{
        width:550px;
        height: 500px;
        margin-top:60px;
        background-color: rgb(243, 243, 238);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .opacity-5{
          opacity: 0.2;
        }
        .bgColor{
          width:150px;
          height: 500px;
          margin-top:-60px;
          background-color: #0a89b0;
        }
        .child{
            width: 347px;
            background-color: #100f0d;
            border: 2px solid black;
            margin-left: 80px;
            margin-top: 60px;
            border-radius: 10px;
            h2,p{
                color: white;
            }
            hr {
              border: 2px dotted #ffffff;
              // border-style: none none dotted; 
              color: #ffffff; 
              // background-color: #fff;
          }
        }
    }
  }
  
  @media screen and (max-width: 800px) {
    .AIMethdologyPage {
      .navbar {
        .hamMenu-home {
          display: flex;
        }
        .menu-items {
          display: none;
        }
        .btns-div {
          display: none;
        }
      }
      .color-box {
        height: 0.5rem;
      }
    }
  }
  