.menu-page-main {
  width: 100%;
  height: calc(100vh - 120px);
  // background-color: #1f304f;
  display: flex;
  justify-content: center;
  align-items: center;

  .menu-container {
    width: 68%;
    height: 80%;
    background-color: white;
    overflow: hidden;
    border-radius: 35px;
    display: flex;
    border: 1px solid #e5e5e5;

    .menu-container-sidebar {
      width: 120px;
      height: 100%;
      border-right: 1px solid #f1f1f1;

      .mcs-header {
        height: 15vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 7vh;
          height: 7vh;
          border-radius: 50%;
          overflow: hidden;
        }
      }

      .mcs-list-wrapper {
        height: calc(100% - 15vh);
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #e5e5e5;
        }

        ul {
          list-style: none;
          padding: 0;

          li {
            // height: 12vh;
            padding: 0.5rem 0;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 3.5vh 0;
            border-right: 0px solid #1f304f;
            transition: 0.2s;

            &:hover {
              img {
                transform: scale(1.2);
              }
            }

            img {
              width: 4.5vh;
              height: 4.5vh;
              transition: 0.3s;
            }

            &.selected-mark {
              border-right: 5px solid #1f304f;
            }
          }
        }
      }

      footer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10vh;
      }
    }

    .menu-container-content {
      width: 100%;
      height: 100%;

      header {
        height: 15vh;
        padding: 0 5%;
        text-align: left;
        vertical-align: middle;
        display: flex;
        align-items: center;

        img {
          height: 7vh;
          margin: auto 0;
        }
      }

      .mcc-section {
        padding: 0 5%;

        ul {
          padding: 0;
          list-style: none;

          li {
            margin-bottom: 4vh;

            .action-card-wrapper {
              width: 100%;
              border: 1px solid #e5e5e5;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 3% 5%;
              border-radius: 15px;
              transition: 0.3s;
              cursor: pointer;

              &:hover {
                border: 1px solid #1F304F;

                .acw-left {
                  img {
                    transform: scale(1.2);
                  }
                }
              }

              .acw-left {
                width: 85%;
                display: flex;
                align-items: center;

                img {
                  height: 4vh;
                  width: 4vh;
                  transition: 0.3s;
                }

                div {
                  text-align: left;
                  padding-left: 12px;

                  h6,
                  p {
                    margin: 0;
                  }

                  h6 {
                    font-weight: 700;
                  }

                  p {
                    font-size: 85%;
                  }
                }
              }

              button {
                background-color: transparent;
                border: 0;
                padding: 4px;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
