.TechnologyPage {
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  .navbar {
    width: 100%;
    height: 4rem;
    padding: 0 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .hamMenu-home {
      display: none;
      position: absolute;
      left: 1rem;
      height: 100%;

      img {
        width: 20px;
      }
    }

    .logo {
      display: flex;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }

    .menu-items {
      display: flex;
      gap: 2rem;
      align-items: center;
      font-size: 0.9rem;

      p {
        margin: 0;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .btns-div {
      display: flex;
      gap: 1rem;

      .gs-Btn {
        color: white;
        font-weight: 500;
        border-radius: 35px;
        background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
        padding: 0.7rem 1.5rem;
        font-size: 0.8rem;
      }

      .business-btn {
        color: white;
        font-weight: 500;
        border-radius: 35px;
        background: #100f0d;
        padding: 0.5rem 1.5rem;
      }

      div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .pathEngine {
    width: 100%;
    height: calc(100% - 5rem);
    overflow-y: scroll;
  }

  .color-box {
    width: 100%;
    height: 1rem;
    background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
  }

  .points {
    list-style-type: none;
    margin-bottom: 20px;
  }

  .tickImage {
    width: 30px;
    height: 30px;
  }

  .firstImage {
    width: 100%;
    padding-left: 100px;
    margin-top: -60px; // Adjust this value as needed to move the image up

    @media screen and (max-width: 800px) {
      padding-left: 0;
      margin-top: -10px; // Adjust this value for mobile view
    }
  }

  .llm-synergised-text {
    margin-top: 50px; // Adjust the margin as needed
    padding-left: 20px;
  
    ul {
      padding-left: 2rem; // Adjust padding to account for custom bullet position
      list-style: none; // Remove default bullet points
      position: relative;
    }
  
    li {
      background-color: #fff; // Background color for the card
      border-radius: 8px; // Rounded corners for the card
      padding:1rem !important; // Padding inside the card
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
      margin-bottom: 1.5rem !important; // Space below each card
      transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition effect */
      border: 1px solid #f0f0f0; // Border color for the card
      cursor: pointer;
      position: relative; // Required for the ::before pseudo-element
  
      &::before {
        content: "->"; /* Custom bullet point */
        position: absolute;
        left: -4rem; /* Adjust according to padding */
        color: #29449d; /* Bullet color */
        font-size: 2rem; /* Bullet size */
        top: 50%;
        transform: translateY(-50%);
      }
  
      &:hover {
        transform: translateY(-5px); /* Slight lift effect on hover */
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
      }
    }
  }
  
  

  .kg-title {
    font-size: 2rem;  // Adjust the font size
    font-weight: 600;   // Adjust the font weight
    color: #29449d;     // Adjust the color
  }

  @media screen and (max-width: 800px) {
    .TechnologyPage {
      .navbar {
        padding: 0 1rem;

        .hamMenu-home {
          display: flex;
        }

        .menu-items,
        .btns-div {
          display: none;
        }
      }

      .color-box {
        height: 0.5rem;
      }

      .llm-synergised-text {
        margin-top: 10px; // Adjust the margin for smaller screens

        ul {
          padding-left: 0;
        }

        li {
          margin-bottom: 8px; // Adjust the space between the list items for smaller screens
        }
      }
    }
  }
}
