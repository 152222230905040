.type-of-streams {
  width: 100%;
  height: 100%;
  border-radius: 35px;
  img {
    height: 20px;
  }
  .streams-about-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6vh 10px;
    img {
      height: 12vh;
      width: 12vh;
      border-radius: 50%;
    }
    h4 {
      font-weight: 700;
      margin: 1vh 0;
    }
    p {
      margin: 0;
    }
  }
  .streams-list {
    height: 35vh;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    padding: 0 12%;
    &::-webkit-scrollbar {
      width: 0;
    }
    .single-app-wrapper {
      width: calc(100% / 3);
      display: flex;
      margin-bottom: 3vh;
      &:nth-child(3n + 1) {
        justify-content: flex-start;
      }
      &:nth-child(3n + 2) {
        justify-content: center;
      }
      &:nth-child(3n + 3) {
        justify-content: flex-end;
      }
      .single-app {
        width: 70%;
        div {
          width: 100%;
          border: 1px solid #e5e5e5;
          aspect-ratio: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          margin-bottom: 10px;
          cursor: pointer;
          img {
            height: 6vh;
            width: 6vh;
          }
          &:hover {
            background-color: #f1f1f1;
            img {
              transform: scale(1.2);
              transition: 0.3s;
            }
          }
        }
        p {
          font-size: 75%;
        }
      }
    }
    .disable-it {
      pointer-events: none;
      opacity: 0.25;
    }
  }
}
