.pathviewPage1 {
  width: 50%;
  height: 100%;

  .pathviewNav1 {
    width: 100%;
    height: 3.5rem;
    border-bottom: 0.5px solid #e5e5e5;
    display: flex;

    .name-div1 {
      width: 30%;
      display: flex;
      align-items: end;
      padding-bottom: 0.5rem;
      padding-left: 35px;
      font-weight: 400;
      font-size: 0.8rem;
    }

    .description-div1 {
      width: 70%;
      display: flex;
      align-items: end;
      padding-bottom: 0.5rem;
      padding-left: 35px;
      font-weight: 400;
      font-size: 0.8rem;
    }
  }

  .pathviewContent1 {
    width: 100%;
    height: 100%;
    border-right: 1px solid #e5e5e5;
    overflow-y: scroll;

    .each-pv-data1 {
      width: 100%;
      border-bottom: 0.5px solid #e5e5e5;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding: 2rem;
      font-size: 0.8rem;
      cursor: pointer;
      transition: all ease 0.2s;

      .each-pv-name1-div {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .each-pv-name1 {
          line-height: 1.6;
          text-transform: capitalize;
        }
      }

      .each-pv-desc1 {
        width: 100%;
        font-weight: 300;
        line-height: 1.6;
      }

      .see-steps-btn {
        width: fit-content;
        padding: 10px 2rem;
        border-radius: 45px;
        border: 1px solid #e5e5e5;
        display: none;
      }

      .see-steps-btn.visible {
        display: block; 
      }

      .see-steps-btn.hidden {
        display: none; 
      }

      &:hover {
        background: rgba(237, 237, 237, 0.368627451);
      }
    }
  }
}
