
.antdOverrideDate{
  
    .ant-picker-dropdown .ant-picker-dropdown-range .ant-picker-dropdown-placement-bottomLeft{
        position: fixed;
        left: 0;
        top: 0; 
    } 
}

.coin-card{
    color: #18191D;
}