
.dashboardAppsMobile{
    display: none;
 }
 @media only screen and (max-width: 600px) {
     .dashboardAppsMobile{
         display: block;
         // display: block;
         // background-color: red;
         // overflow-y: scroll;
         // overflow-x: scroll;
         // height: 40vh;
         padding-bottom: 20vh;
         // position: absolute;
         // top:20;
         // overflow-y: scroll;
         height: 100vh
         // justify-content: 'flex-start';
     }
   }
 
 