.gmap-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .map-content {
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .place-details {
    width: 30%;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    top: 5px;
    bottom: 10px;
    right: 5px;
    background: white;
    height: calc(100% - 15px);
    padding: 1rem;
    border-radius: 10px;
    gap: 1rem;
    overflow-y: scroll;

    .place-photo {
      display: flex;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .place-name {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .user-rating-div {
      display: flex;
      align-items: center;
      gap: 1rem;

      .ratings {
        font-size: 1.25rem;
        font-weight: 500;
        display: flex;
        gap: 0.5rem;
        align-items: center;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    .address-div {
      display: flex;
      gap: 1rem;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .phone-no-div {
      display: flex;
      gap: 1rem;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .web-div {
      display: flex;
      gap: 1rem;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .reviews-div {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .reviews-text {
        font-weight: 500;
        margin-top: 1rem;
      }

      .each-review {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .author-div {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 35px;
            height: 35px;
          }
        }

        .author-rating-div {
          display: flex;
          align-items: center;
          gap: 10px;
          text-transform: lowercase;

          img {
            width: 15px;
            height: 15px;
          }
        }

        .author-text {
          font-size: 0.8rem;
          display: -webkit-box;
          -webkit-line-clamp: 4; /* Limit to 4 lines */
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-wrap: break-word;
        }
      }
    }
  }
}
