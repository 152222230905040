.mapspage {
  width: 100%;
  height: 100%;
  position: relative;

  .maps-navbar {
    width: 100%;
    height: 4rem;
    padding: 0 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .hamMenu {
      display: none;
      position: absolute;
      left: 1rem;
      height: 100%;

      img {
        width: 20px;
      }
    }

  
    .logo {
      display: flex;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }

    .menu-items {
      display: flex;
      gap: 2rem;
      align-items: center;
      font-size: 0.9rem;

      p {
        margin: 0;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .btns-div {
      display: flex;
      gap: 1rem;

      .gs-Btn {
        color: white;
        font-weight: 500;
        border-radius: 35px;
        background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
        padding: 0.7rem 1.5rem;
        font-size: 0.8rem;
      }

      .business-btn {
        color: white;
        font-weight: 500;
        border-radius: 35px;
        background: #100f0d;
        padding: 0.5rem 1.5rem;
      }

      div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .maps-color-box {
    width: 100%;
    height: 1rem;
    background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
  }

  .maps-container {
    width: 100%;
    height: calc(100% - 5rem);
    display: flex;

    .maps-sidebar {
      width: 25%;
      height: 100%;
      border-right: 1px solid #e5e5e5;
      overflow-y: scroll;

      .top-icons {
        display: flex;
        padding: 1rem;
        border-bottom: 1px solid #e5e5e5;
        gap: 2rem;
        height: 6.5rem;

        .each-icon {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          align-items: center;
          cursor: pointer;
          transition: all ease 0.2s;

          .border-div {
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;

            img {
              width: 60%;
              height: 60%;
            }
          }

          .icon-name-txt {
            font-size: 0.7rem;
            text-align: center;
          }

          &:hover {
            .border-div {
              box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            }

            .icon-name-txt {
              transform: scale(1.05);
            }
          }
        }
      }

      .mid-area {
        width: 100%;
        display: flex;
        flex-direction: column;

        .s-destination-div {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 1rem;
          padding: 2rem 1rem;
          border-bottom: 1px solid #e5e5e5;

          .input-div1 {
            width: 100%;
            border: 1px solid #e5e5e5;
            border-radius: 45px;

            input {
              width: 100%;
              border: none;
              padding: 1rem;
              border-radius: 45px;
            }
          }
        }

        .each-filter-div {
          display: flex;
          flex-direction: column;
          width: 100%;
          border-bottom: 1px solid #e5e5e5;

          .visible-div {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: 2rem 1rem;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              background: rgba(237, 237, 237, 0.368627451);
            }
          }

          .hidden-div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 2rem 1rem;
          }

          .hidden-div1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 2rem 1rem;
          }
        }

        .destination-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .dest-txt {
            font-size: 0.9rem;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              cursor: pointer;
              transition: all ease 0.2s;

              &:hover {
                transform: scale(1.05);
              }
            }
          }

          .input-div2 {
            width: 100%;
            border: 1px solid #e5e5e5;
            border-radius: 45px;

            input {
              width: 100%;
              border: none;
              padding: 1rem;
              border-radius: 45px;
            }

            .react-datepicker-wrapper {
              width: 100%;
            }
          }
        }

        .add-div {
          width: fit-content;
          font-size: 0.8rem;
          display: flex;
          align-items: center;
          gap: 7px;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }

        .maps-btns-div {
          width: 100%;
          display: flex;
          padding: 2rem 1rem;

          .gs-Btn-maps {
            width: 100%;
            color: white;
            font-weight: 500;
            border-radius: 35px;
            text-align: center;
            background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
            padding: 0.9rem 1.5rem;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              transform: scale(1.05);
            }
          }

          .reset-btn {
            color: #100f0d;
            border-radius: 35px;
            text-align: center;
            font-size: 0.8rem;
            padding: 0.9rem 1.5rem;
            font-weight: 500;
            border: 1px solid #e5e5e5;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
    }

    .maps-content-area {
      width: 75%;
      height: 100%;

      .pathviewPage1-step-details {
        width: 100%;
        height: 100%;
        padding: 2rem;
        overflow-y: scroll;
        color: #1f304f;

        .pathviewPage1-journey-top-area {
          width: 100%;
          height: 8rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          position: relative;

          .pathviewPage1-bold-text {
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: capitalize;
          }

          .pathviewPage1-journey-des {
            font-size: 0.8rem;
            font-weight: 300;
            line-height: 1.7;
            text-transform: capitalize;
          }

          .pathviewPage1-goBack-div {
            position: absolute;
            top: 0;
            right: 0;
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              transform: scale(1.05);
            }
          }
        }

        .pathviewPage1-journey-steps-area {
          display: flex;
          width: 100%;
          gap: 0 4rem;
          flex-wrap: wrap;

          .pathviewPage1-each-j-step {
            width: calc((100% - 8rem) / 3);
            border-radius: 10px;
            border: 1px solid #e5e5e5;
            display: flex;
            flex-direction: column;
            padding: 2rem 1rem;
            gap: 2rem;
            margin-top: 2rem;
            z-index: 2;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              border: 1px solid #1f304f;
            }

            .pathviewPage1-each-j-step-text {
              width: 100%;
              font-size: 1.1rem;
              font-weight: 500;
              text-transform: capitalize;
            }

            .pathviewPage1-each-j-step-text1 {
              width: 100%;
              font-size: 0.8rem;
              font-weight: 300;
              line-height: 1.7;
              margin-top: -1rem;
              text-transform: capitalize;
            }

            .pathviewPage1-each-j-amount-div {
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: space-between;

              .pathviewPage1-each-j-amount {
                font-size: 1.1rem;
                font-weight: 500;
                text-transform: capitalize;
              }
            }
          }

          .pathviewPage1-relative-div {
            position: relative;

            .pathviewPage1-j-arr-div {
              position: absolute;
              inset: 0;
              width: 100%;
              height: 100%;
              display: flex;
              justify-self: center;
              align-items: center;
              left: calc(100% + 1rem);
              z-index: 1;
              cursor: default;
            }
          }
        }
      }

      .maps-page-seperator {
        width: 150vw;
        height: 100%;
        display: flex;
      }

      .path-options-div1 {
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;

        .path-options1 {
          width: fit-content;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 1rem 2rem;
          border: 0.5px solid #e5e5e5;
          border-bottom-left-radius: 45px;
          border-bottom-right-radius: 45px;
          border-top: none;

          .each-path-opt1 {
            font-size: 0.8rem;
            padding: 5px 1.5rem;
            border-radius: 25px;
          }

          .toggleContainer2 {
            width: 2.5rem;
            border: 1px solid #d9d9d9;
            border-radius: 35px;
            margin-left: 10px;
            margin-right: 10px;
            cursor: pointer;

            .toggle2 {
              margin: 2px;
              width: 14px;
              height: 14px;
              background: linear-gradient(
                90deg,
                #47b4d5 0.02%,
                #29449d 119.26%
              );
              border-radius: 50%;
              transition: all 0.5s ease;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .maps-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 7;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-container {
      background: white;
      min-width: 25%;
      padding: 2rem 1.5rem;
      border-radius: 25px;
      border: 1px solid #e5e5e5;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;
      z-index: 8;

      .save-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .save-input-div {
          width: 100%;
          height: 3.5rem;
          border-radius: 45px;
          border: 1px solid #e5e5e5;

          input {
            width: 100%;
            height: 100%;
            padding: 0 1rem;
            border: none;
            outline: none;
            border-radius: 45px;
            font-size: 1rem;
          }
        }
      }

      .cont-Btn {
        color: white;
        width: 100%;
        border-radius: 35px;
        background: linear-gradient(90deg, #47b4d5 0.02%, #29449d 119.26%);
        padding: 1.1rem 0;
        text-align: center;
        font-size: 0.85rem;
        font-weight: 500;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .mapspage {
    .maps-navbar {
      .hamMenu {
        display: flex;
      }
      .menu-items {
        display: none;
      }
      .btns-div {
        display: none;
      }
    }

    .maps-color-box {
      height: 0.5rem;
    }

    .maps-container {
      height: calc(100% - 4.5rem);
      .maps-sidebar {
        width: 100%;
        .mid-area {
          .input-div1 {
            width: 80%;
          }
          .destination-container {
            .input-div2 {
              width: 80%;
            }
          }
        }
      }
    }
  }
}
