
.modalStyle{
  padding: 50px; 
  color: #182542;
  height: 600px;

  .headerSection{
    font-size: 30px;
    font-weight: 800;
    color: #18191D;
    text-align: center;
    padding-bottom: 40px;
  }
  
  .breadcrumb{
    // font-weight: bold;
    font-size: 13px;
    padding-bottom: 40px;
  }
  
  .titleSection{
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 33px;
  }
  
  .menuCards{

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    border:  0.5px solid #E5E5E5;
    padding: 28px 31px;
    height: 86px;
    cursor: pointer;

    &:hover{
      background-color: #E7E7E780;
    }
  
  }

  .menuCards1{

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    border:  0.5px solid #E5E5E5;
    padding: 28px 31px;
    height: 86px;
  
  }
  
  .footerBack{
  background-color: #E7E7E780;
  }
  

  .breadcrumb{
    // font-weight: bold;
    font-size: 13px;
    padding-bottom: 40px;
    .crumbs{
      &:hover{
        text-decoration: underline;
        cursor: pointer;
      }
    }
    
  }


}


.otpView {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 0;
  .label {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10%;
  }
  .otpInputWrapper {
    width: 70%;
    justify-content: space-between;
    margin-bottom: 4%;
    > div {
      flex: 0 0 20%;
      height: 90px;
      .otpInput {
        width: 100% !important;
        height: 100%;
        border: 2px solid #E7E7E780;
        border-radius: 8px;
        font-size: 28px;
      }
    }
  }
}


.changeapp-data{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
  border: 1px solid rgb(251, 251, 251);
}

.changeapp-search{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border: 1px solid transparent;
}