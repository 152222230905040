.sidebar {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // padding: 0px 30px;
  border-right: solid 1px #e7e7e7;
  cursor: pointer;
  width: 90px;

}

.sidebar>div {

  &:hover {
    opacity: 1;
  }
}




.sidebar-loggedin {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: solid 1px #e7e7e7;
  width: 85px;

  &>div {
    &:hover {
      opacity: 1;
    }
  }

  &>div:first-child {
    padding-top: 15px;
  }

}

.sidebar-loggedout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: solid 1px #e7e7e7;
  width: 85px;

  &>div {
    &:hover {
      opacity: 1;
    }
  }

  &>div:first-child {
    padding-top: 40px;
  }

  &>div:last-child {
    padding-bottom: 40px;
  }
}

.globalItem {
  opacity: 0.3;
  margin-top: 30px;
  margin-bottom: 30px;
  // border: 0.5px solid #E7E7E7;
  border-radius: 10px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    opacity: 1 !important;
    -ms-transform: scale(1.08);
    /* IE 9 */
    -webkit-transform: scale(1.08);
    /* Safari 3-8 */
    transform: scale(1.08);
    transition: 0.3s all ease-in-out;
  }
}

.lock {
  height: 138px;
  // border-top: solid 1px #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  flex-direction: column;
  // position: absolute;
  // bottom: 0;
}

.bottomIcon {
  border-top: solid 1px #e7e7e7;
  cursor: pointer;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
}

.collapseButton {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 0.5px solid #E7E7E7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 72px;
  top: 67px;
  background: #4B9DDC;
  ;
  cursor: pointer;
  z-index: 1;
}