.change-currency-main {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.73);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  .change-currency-modal {
    width: 29%;
    border-radius: 35px;
    overflow: hidden;
    animation: be-big 0.3s 1 linear;
    .c-c-m-body {
      width: 100%;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 5vh;
      .c-c-m-above {
        width: 80%;
        margin-bottom: 4vh;
        h3,
        p {
          margin: 0;
        }
        h3 {
          font-weight: 700;
          color: #1f304f;
        }
        p {
          font-size: 80%;
          margin-bottom: 4vh;
        }
        input {
          width: 100%;
          border: 1px solid #e5e5e5;
          padding: 7px;
          font-size: 85%;
          border-radius: 3px;
        }
      }
      .c-c-m-below {
        width: 80%;
        height: 40vh;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 0;
        }
        .coin-list-loader {
          width: 29%;
          margin-bottom: 2vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          div {
            width: 100%;
            aspect-ratio: 1;
            border: 1px solid #e5e5e5;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            margin-bottom: 5px;
            button {
              height: 5vh;
              width: 5vh;
              border-radius: 50%;
              border: 0;
            }
          }
          p {
            margin: 0;
            font-size: 65%;
            margin-bottom: 5px;
            background-color: #e5e5e5;
            color: #e5e5e5;
          }
        }
        .single-coin {
          width: calc(100% / 3);
          display: flex;
          flex-direction: column;
          margin-bottom: 2vh;
          img {
            height: 20px;
          }
          &:nth-child(3n + 1) {
            align-items: flex-start;
          }
          &:nth-child(3n + 2) {
            align-items: center;
          }
          &:nth-child(3n + 3) {
            align-items: flex-end;
          }
          .single-coin-wrapper {
            width: 85%;
            display: flex;
            flex-direction: column;
            align-items: center;
            div {
              width: 100%;
              aspect-ratio: 1;
              border: 1px solid #e5e5e5;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 2vh;
              border-radius: 15px;
              &:hover {
                background-color: #f1f1f1;
                cursor: pointer;
              }
              img {
                height: 5vh;
                width: 5vh;
              }
            }
            p {
              font-size: 75%;
            }
            p,
            h6 {
              margin: 0;
            }
            h6 {
              font-weight: 600;
              font-size: 85%;
            }
          }
        }
      }
    }
    .c-c-m-footer {
      height: 7vh;
      width: 100%;
      background-color: #1f304f;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        width: 80%;
        display: flex;
        justify-content: space-between;
        h6 {
          margin-bottom: 0;
          color: #ffffff;
          font-weight: 700;
          margin-top: 0;
        }
      }
    }
  }
}
