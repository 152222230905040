.kanban-board {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.board-column {
  width: 24%;
  padding: 10px;

  .heading-wrapper {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .borderDiv {
    border: 0.5px solid transparent;
  }
}

.card {
  background-color: #fff;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  height: 150px;
}

button {
  background-color: #5cb85c;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
}

button:hover {
  background-color: #449d44;
}

.droppable-area {
  min-height: 100px;
  padding: 8px;
  background-color: #f4f4f4;
  border-radius: 4px;
}
