.notification-portal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 2%;
  overflow: hidden;
  .notification-box {
    width: 30%;
    min-height: 10%;
    border-radius: 2px;
    background: linear-gradient(145deg, #f1f1f1, #ffffff);
    position: relative;
    animation: 0.3s from-right 1 linear;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.2),
      -8px -8px 10px rgba(0, 0, 0, 0.2);
    h5,
    p {
      margin: 0;
      text-align: left;
    }
    button {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: transparent;
      border: 0;
      &:hover {
        img {
          transform: scale(1.2);
          transition: 0.3s;
        }
      }
      img {
        height: 20px;
        width: 20px;
      }
    }
    padding: 2.8vh;
    padding-right: 5vh;
    h5 {
      margin-bottom: 1vh;
      font-weight: 600;
    }
    p {
      font-size: 100%;
    }
    div {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
.closing-it {
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  animation: 0.3s dim-it 1 linear;
  .notification-box {
    animation: 0.3s from-left 1 linear;
  }
}
@keyframes dim-it {
  from {
    opacity: 1;
    width: 100%;
    height: 100%;
    padding: 2%;
  }
  to {
    opacity: 0;
    width: 100%;
    height: 100%;
    padding: 2%;
  }
}
@keyframes from-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes from-left {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
