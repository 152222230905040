.mypaths {
  width: 100%;
  height: 100%;

  .mypaths-menu {
    width: 100%;
    height: 4rem;
    padding: 12px 30px;
    display: flex;
    gap: 2.5vw;
    border-bottom: 0.5px solid rgb(229, 229, 229);

    .each-mypath-menu {
      padding: 10px 30px;
      border-radius: 35px;
      font-weight: 400;
      font-size: 15px;
      color: #1f304f;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .mypaths-content {
    width: 100%;
    height: calc(100% - 4rem);

    .mypathsNav {
      width: 100%;
      height: 2.5rem;
      border-bottom: 0.5px solid #e5e5e5;
      display: flex;
      align-items: center;

      .mypaths-name-div {
        width: 30%;
        display: flex;
        align-items: end;
        padding-left: 35px;
        font-weight: 400;
        font-size: 0.9rem;
      }

      .mypaths-description-div {
        width: 50%;
        display: flex;
        align-items: end;
        padding-left: 35px;
        font-weight: 400;
        font-size: 0.9rem;
      }

      .mypathsName {
        width: 29%;
        display: flex;
        padding-left: 35px;
        font-weight: 500;
        font-size: 0.8rem;
      }

      .mypathsCountry {
        width: 12%;
        display: flex;
        font-weight: 500;
        font-size: 0.8rem;
      }

      .mypathsVendors {
        width: 10%;
        display: flex;
        font-weight: 500;
        font-size: 0.8rem;
      }

      .mypathsMicrosteps {
        width: 15%;
        display: flex;
        font-weight: 500;
        font-size: 0.8rem;
        padding-right: 35px;
      }
    }

    .mypathsScroll-div {
      width: 100%;
      height: calc(100% - 2.5rem);
      overflow-y: scroll;

      .each-mypaths-data {
        width: 100%;
        border-bottom: 0.5px solid #e5e5e5;
        display: flex;
        font-size: 0.8rem;
        cursor: pointer;
        transition: all ease 0.2s;

        .each-mypaths-name {
          width: 30%;
          padding: 2rem 0 2rem 35px;
          line-height: 1.7;
          font-size: 0.9rem;
        }

        .each-mypaths-desc {
          width: 50%;
          padding: 2rem 0 2rem 0;
          font-weight: 300;
          padding-left: 35px;
          line-height: 1.8;
          // text-transform: none;
          padding-right: 1rem;
        }

        &:hover {
          background: rgba(237, 237, 237, 0.368627451);
        }
      }

      .each-mypaths-data1 {
        width: 100%;
        border-bottom: 0.5px solid #e5e5e5;
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
        padding: 2rem 0 8rem 0;
        cursor: pointer;
        transition: all ease 0.2s;

        .each-mypaths-detail {
          width: 100%;
          display: flex;
          align-items: center;

          .each-mypathsName {
            width: 29%;
            display: flex;
            align-items: center;
            padding-left: 35px;
            font-weight: 500;
            font-size: 0.9rem;
            gap: 10px;
          }

          .each-mypathsCountry {
            width: 12%;
            display: flex;
            font-weight: 500;
            font-size: 0.9rem;
          }

          .each-mypathsVendors {
            width: 10%;
            display: flex;
            font-weight: 500;
            font-size: 0.9rem;
          }

          .each-mypathsMicrosteps {
            width: 15%;
            display: flex;
            font-weight: 500;
            font-size: 0.9rem;
            padding-right: 35px;
          }
        }

        .each-mypaths-desc {
          width: 100%;
          padding: 3rem 35px 0 35px;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .each-mypaths-desc-txt {
            font-size: 0.9rem;
            font-weight: 500;
          }

          .each-mypaths-desc-txt1 {
            line-height: 1.8;
            font-size: 0.8rem;
            font-weight: 300;
            text-transform: none;
          }
        }

        &:hover {
          background: rgba(237, 237, 237, 0.368627451);
        }
      }
    }
  }
}
